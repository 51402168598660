import React from "react"
import RehypeReact from "rehype-react"

export const renderAst = (components, element) => {
  const render = new RehypeReact({
    createElement: React.createElement,
    components: {
      ...components,
    },
  }).Compiler

  return render(element)
}
