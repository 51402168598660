import React from "react"
import { graphql } from "gatsby"
import { useSpring, animated } from "react-spring"

import SEO from "../components/seo"
import { renderAst } from "../components/ast/renderAst"
import { BodyTextAst } from "../components/ast/bodyTextAst"

export default function Template({ data }) {
  const springProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })
  const { markdownRemark } = data
  const { frontmatter, htmlAst } = markdownRemark
  const path = frontmatter.path.replace(/\//g, "")

  return (
    <>
      <SEO
        title={frontmatter.title}
        description={frontmatter.metaDescription}
        type="article"
        image={null}
        pathname={path}
        robots={`noindex, follow`}
      />
      <animated.section
        className="p-4 pt-0"
        style={{ marginTop: "86px", ...springProps }}
      >
        <div className="container mx-auto">
          <h1 className="leading-loose text-3xl mb-8">{frontmatter.title}</h1>
          {renderAst(
            {
              p: BodyTextAst,
              h2: ({ children }) => <h2 className="text-2xl">{children}</h2>,
            },
            htmlAst
          )}
        </div>
      </animated.section>
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        path
        title
      }
    }
  }
`
